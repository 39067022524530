.cover {
  width: 250px;
  height: 250px;
  transition: transform 0.1s ease-in-out;

  background-size: contain;
  background-repeat: no-repeat;
  background-color: black;
  background-position: center;

  border-radius: 20px;

  margin: 10px;

  cursor: pointer;
}

.cover:hover {
  transform: scale(1.05);
}

.card {
  height: 85vh;
  width: 85vw;

  min-width: 300px;
  max-width: 800px;

  position: relative;

  .close {
    position: absolute;
    top: 15px;
    right: 15px;

    width: 25px;
    height: 25px;

    transition: transform 0.1s ease-in-out;

    cursor: pointer;
  }

  .close:hover {
    transform: scale(1.3);
  }

  .wrapper {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;

    height: 100%;
    width: 100%;

    overflow-y: hidden;

    .header {
      .title {
        text-align: center;
        margin: 0;

        font-size: 2.5em;
        margin: 0px 50px;
      }

      .description {
        margin: 10px 10px 0px 10px;
        text-align: center;
      }

      .createdBy {
        text-align: center;
        margin: 10px 10px 0px 10px;

        .author {
          color: black;
          font-weight: bold;
        }
      }
    }
    .lineBreak {
      height: 3px;
      width: calc(100% - 30%);

      margin: 15px 15%;

      background-color: rgba($color: #000000, $alpha: 0.3);
      border-radius: 3px;
    }

    .section {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 100%;
      width: 100%;

      overflow-y: hidden;
    }
  }
}
