.page {
  height: calc(100% - 170px);

  margin-top: 70px;
  margin-bottom: 100px;

  overflow-y: auto;

  .section {
    display: flex;
    justify-content: center;
    align-items: center;

    min-height: 100%;
  }
}
