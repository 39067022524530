.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  position: fixed;
  bottom: 0;

  background-color: #494949;

  width: 100%;
  height: 100px;

  overflow-x: auto;

  .controls {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    .information {
      display: flex;
      flex-flow: row;

      min-width: 200px;
      max-width: 300px;
      margin-left: 10px;

      img {
        width: 64px;
        height: 64px;
      }

      .words {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: flex-start;

        margin-left: 15px;

        color: white;
      }
    }

    .nowPlaying {
      display: flex;
      flex-flow: column;

      width: 100%;
      max-width: 500px;

      margin: 0px 10px;

      .buttons {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 5px;

        width: 100%;
      }

      .slider {
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;

        width: 100%;

        input {
          width: 100%;
        }

        .time {
          color: white;
        }
      }
    }

    .volume {
      min-width: 75px;
      max-width: 150px;
      margin-right: 10px;
    }

    .iconButton {
      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 50%;

      width: 50px;
      height: 50px;

      margin: 0;

      img {
        height: 25px;
        width: 25px;

        fill: white;
      }
    }
  }
}
