.header {
  position: fixed;
  top: 0;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  background-color: rgba($color: #494949, $alpha: 1.0);

  z-index: 1;

  width: 100%;
  height: 70px;
}
