.backdrop {
  position: fixed;
  top: 0;
  z-index: 2;

  height: 100vh;
  width: 150vw;

  background-color: rgba($color: #000000, $alpha: 0.5);

  display: flex;
  justify-content: center;
  align-items: center;
}
