.table {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;

  height: 100%;
  width: 85%;

  overflow-y: hidden;

  .header {
    display: flex;
    flex-flow: row;

    width: 100%;

    padding: 0px 0px 5px 0px;

    .title {
      width: calc(50% - 5px);

      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .content {
    height: 100%;
    width: 100%;

    overflow-y: auto;
    scrollbar-width: thin;
  }
}
