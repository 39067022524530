.row {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;

  height: fit-content;
  width: 100%;

  .elm {
    width: calc(50% - 5px);

    padding: 5px 5px 5px 0px;

    .artist {
      margin-left: 5px;
      cursor: pointer;
    }
  }
}

.row:nth-child(even) {
  background-color: #e2e2e2;
}

.row:nth-child(odd) {
  background-color: #ffffff;
}
