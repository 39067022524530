html,
body,
#root {
  height: 100vh;
  overflow-y: hidden;

  margin: 0;
  padding: 0;

  background-color: #494949;
}

* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.styled-button {
  margin: 15px 0px 15px 25px;

  padding: 6px 16px;

  color: white;
  font-size: 1em;
  text-emphasis: bold;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 1.75;
  font-weight: 500;

  background-color: #3f51b5;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;

  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);

  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    cursor: pointer;
}

.styled-button:hover {
  background-color: #303f9f;
}

.styled-button:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}
